import useTranslation from "next-translate/useTranslation";
import { useContext } from "react";

import { UserContext } from "../lib/context";
import CircularProgress from "./CircularProgress";
import ProviderCard from "./ProviderCard";

export default function Providers({ providers, locale }) {
  const { user, clientData } = useContext(UserContext);

  let pinnedProviders, allProviders;
  if (clientData && clientData.client_favouriteProviderIds) {
    pinnedProviders = providers.filter((provider) =>
      clientData.client_favouriteProviderIds.includes(provider.uid)
    );
    allProviders = providers.filter(
      (provider) =>
        !clientData.client_favouriteProviderIds.includes(provider.uid)
    );
  } else {
    pinnedProviders = [];
    allProviders = providers;
  }

  const { t } = useTranslation("common");

  return (
    <div className="">
      {providers ? (
        user && clientData ? (
          <div>
            {pinnedProviders && pinnedProviders.length > 0 ? (
              <>
                {/* <span className="block text-sm text-gray-500 dark:text-gray-400 mb-4 mt-10 mx-10 font-semibold">
                  {t("restaurants.pinned")}
                </span> */}
                <div className="flex flex-wrap">
                  {pinnedProviders.map((provider) => (
                    <ProviderCard
                      key={`pinned-${provider.uid}`}
                      provider={provider}
                      locale={locale}
                    />
                  ))}
                </div>
              </>
            ) : null}
            {allProviders && allProviders.length > 0 ? (
              <>
                {/* <span className="block text-sm text-gray-500 dark:text-gray-400 mb-4 mt-10 mx-10 font-semibold">
                  {t("restaurants.all")}
                </span> */}
                <div className="flex flex-wrap">
                  {allProviders.map((provider) => (
                    <ProviderCard
                      key={`all-${provider.uid}`}
                      provider={provider}
                      locale={locale}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </div>
        ) : (
          <>
            {/* <span className="block text-sm text-gray-500 dark:text-gray-400 mb-4 mt-10 mx-10 font-semibold">
              {t("restaurants.all")}
            </span> */}
            <div className="flex flex-wrap">
              {providers.map((provider) => (
                <ProviderCard
                  key={`total-${provider.uid}`}
                  provider={provider}
                  locale={locale}
                />
              ))}
            </div>
          </>
        )
      ) : (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}


// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { collection, query, getDocs, where, orderBy } from "firebase/firestore";
import { ArrowRightIcon, DesktopComputerIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import Link from "next/link";

import AnimatedCarousel from "../components/AnimatedCarousel";
import MultiplaformDevices from "../components/MultiplatformDevices";
import Providers from "../components/Providers";
import { firestore, providerToJSON } from "../lib/firebase";
import { Provider } from "../types/src";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import Image from "next/image";

type Props = {
  providers: Provider[];
  locale: string;
};

function Home(props: Props) {
  const { t } = useTranslation("home");

  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      <Head>
        <title>{t("meta.title")}</title>
      </Head>
      <div className="box-border ">
        <AnimatedCarousel></AnimatedCarousel>
      </div>

      <Tab.Group>
        <Tab.List className="mt-4 w-80 mx-auto flex space-x-1">
          <Tab
            key={"providers"}
            className={({ selected }) =>
              classNames(
                "rounded-full shadow font-grotesk border-box w-full py-2.5 text-sm leading-5 font-medium transition-all",
                "outline-none ",
                selected
                  ? "border-2 border-teal-500 dark:border-teal-200 bg-teal-500/20 dark:bg-teal-200/20"
                  : "bg-white dark:bg-gray-800 hover:bg-teal-500/10 dark:hover:bg-teal-200/20"
              )
            }
          >
            {t("providers")}
          </Tab>
          <Tab
            key={"menuOnlyProviders"}
            className={({ selected }) =>
              classNames(
                "rounded-full shadow font-grotesk border-box w-full py-2.5 text-sm leading-5 font-medium transition-all",
                "outline-none ",
                selected
                  ? "border-2 border-teal-500 dark:border-teal-200 bg-teal-500/20 dark:bg-teal-200/20"
                  : "bg-white dark:bg-gray-800 hover:bg-teal-500/10 dark:hover:bg-teal-200/20"
              )
            }
          >
            {t("menuOnlyProviders")}
          </Tab>
        </Tab.List>
        <Tab.Panels className="block mt-2">
          <Tab.Panel key={"providers"} className="p-4">
            <Providers
              providers={props.providers.filter((p) => !p.store_menu_only)}
              locale={props.locale}
            ></Providers>
          </Tab.Panel>
          <Tab.Panel key={"menuOnlyProviders"} className="p-4">
            <Providers
              providers={props.providers.filter((p) => p.store_menu_only)}
              locale={props.locale}
            ></Providers>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      <MultiplaformDevices></MultiplaformDevices>

      <div className="pt-12 relative bg-white dark:bg-gray-900 border-t border-black/10 dark:border-white/10 shadow-xl font-grotesk">
        <img
          alt="carciofo"
          data-aos="zoom-in-down"
          className="hidden sm:block dark:hidden h-64 absolute top-4 left-4"
          src="/fruit/carciofo.png"
          loading="lazy"
        />
        <img
          alt="pumpkin"
          data-aos="zoom-in-down"
          className="hidden sm:block dark:hidden h-48 absolute bottom-4 right-4"
          src="/fruit/kurbis.png"
          loading="lazy"
        />

        <img
          alt="melanzane"
          data-aos="zoom-in-down"
          className="hidden sm:block dark:hidden h-48 absolute top-80 right-4"
          src="fruit/melanzane.png"
          loading="lazy"
        />

        <img
          alt="chili"
          data-aos="zoom-in-down"
          className="hidden sm:block dark:hidden h-52 absolute bottom-1/3 left-4"
          src="fruit/chili.png"
          loading="lazy"
        />
        <h2 className="text-3xl font-semibold mx-auto text-center my-6">
          {t("how.title")}
        </h2>

        <div className="w-full pb-16">
          <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-center text-center flex-wrap px-6">
            <div className="flex-1 mt-10 flex flex-col items-center">
              <div className="mt-4 mb-2">
                <Image
                  src="/mockups/device_partner.png"
                  alt="Phone menu"
                  objectFit="contain"
                  height={375}
                  width={300}
                />
              </div>
              <div className="bg-bringmos-500 text-white rounded-full h-8 w-8 shadow text-xl flex items-center justify-center">
                <span>1</span>
              </div>
              <p className="my-4 font-grotesk text-xl">{t("how.one.title")}</p>
              <p className="text-gray-500 dark:text-gray-300 text-xs md:text-sm">
                {t("how.one.description")}
              </p>
            </div>

            <i className="hidden md:block md:mx-4 text-xl mt-5 md:mt-40 md:text-3xl las la-arrow-right opacity-50"></i>

            <div className="flex-1 mt-10 flex flex-col items-center">
              <Image
                src="/mockups/client_menu.png"
                alt="Phone menu"
                objectFit="contain"
                height={400}
                width={300}
              />
              <div className="bg-bringmos-500 text-white rounded-full h-8 w-8 shadow text-xl flex items-center justify-center">
                <span>2</span>
              </div>
              <p className="my-4 font-grotesk text-xl">{t("how.two.title")}</p>
              <p className="text-gray-500 dark:text-gray-300 text-xs md:text-sm">
                {t("how.two.description")}
              </p>
            </div>

            <i className="hidden md:block md:mx-4 text-xl mt-5 md:mt-40 md:text-3xl las la-arrow-right opacity-50"></i>

            <div className="flex-1 mt-10 flex flex-col items-center">
              <Image
                src="/mockups/client_checkout.png"
                alt="Phone menu"
                objectFit="contain"
                height={400}
                width={300}
              />
              <div className="z-10 bg-bringmos-500 text-white rounded-full h-8 w-8 shadow text-xl flex items-center justify-center">
                <span>3</span>
              </div>
              <p className="my-4 font-grotesk text-xl">
                {t("how.three.title")}
              </p>
              <p className="text-gray-500 dark:text-gray-300 text-xs md:text-sm">
                {t("how.three.description")}
              </p>
            </div>
          </div>
        </div>

        <div className="z-10 relative px-6 flex flex-col items-center text-center md:text-left md:flex-row border-t border-b border-black/10 dark:border-white/10 py-8 max-w-6xl mx-auto bg-gradient-to-r from-white dark:from-gray-900 via-gray-100 dark:via-gray-800 to-white dark:to-gray-900">
          <div>
            <h3 className="text-2xl font-grotesk mb-4">{t("propose.title")}</h3>
            <p className="font-grotesk">{t("propose.description")}</p>
          </div>
          <Link href="https://bringmos.io/register">
            <a
              target="_blank"
              className="bringmos-primary-btn md:ml-10 rounded-full py-2 px-6 text-lg bg-gradient-to-br from-bringmos-500 to-bringmos-700"
            >
              {t("propose.register")}
            </a>
          </Link>
        </div>

        <div className="container m-auto pb-10">
          <div className="flex flex-col items-center py-8 px-6">
            <div className="shadow mb-6 rounded-full bg-gradient-to-br from-cyan-500 to-indigo-500 text-white py-0 px-4">
              {t("new.label")}
            </div>
            <h4 className="text-3xl font-semibold mb-6 text-center">
              {t("new.title")}
            </h4>

            <p className="my-4 max-w-4xl mx-auto font-normal md:text-lg text-center">
              {t("new.description")}
            </p>

            <Link href="https://bringmos.io" target="_blank">
              <a className="bringmos-primary-btn rounded-full py-2 px-6 text-lg bg-gradient-to-br from-bringmos-500 to-bringmos-700">
                {t("new.more")}
              </a>
            </Link>

            <div className="max-w-3xl mx-auto py-4">
              <Image
                width={2512}
                height={1870}
                src="/mockups/menu_only_scan_me.png"
                alt="scan me mockup"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

 async function _getServerSideProps({ locale }) {
  const col = collection(firestore, "Providers");
  const providerQuery = query(
    col,
    where("store_visible", "==", true),
    where("category", "==", "restaurant"),
    orderBy("store_acceptOrders", "desc"),
    orderBy("rank", "desc")
  );

  const providers = (await getDocs(providerQuery)).docs.map(providerToJSON);
  return {
    props: {
      providers,
      locale,
    },
  };
}

export default Home;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  
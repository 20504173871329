import {
  mdiPin,
  mdiPinOutline,
  mdiStar,
  mdiStarHalfFull,
  mdiStarOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  setDoc,
} from "firebase/firestore";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";
import { useContext } from "react";

import { UserContext } from "../lib/context";
import { firestore } from "../lib/firebase";
import { Provider } from "../types/src";
import { isShopOpen } from "../utils/time";

export default function ProviderCard({
  provider,
  locale,
}: {
  provider: Provider;
  locale: string;
}) {
  const { t } = useTranslation("common");

  const { user, clientData } = useContext(UserContext);

  let fullStars = 0;
  if (provider?.rating) {
    fullStars = Math.round(provider.rating.avg - 0.0001);
  }

  const iconButton =
    "transition-all outline-none flex items-center justify-center h-8 w-8 rounded-full hover:bg-opacity-10 dark:hover:bg-opacity-10 hover:bg-gray-800 dark:hover:bg-gray-400 focus:outline-none";

  const backgroundStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) center center / cover, url('${provider.coverURL}') no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const isOpen = isShopOpen(provider);

  return (
    <Link href={`/${provider.link}`}>
      <a className="no-underline relative group cursor-pointer w-full md:max-w-100 h-96 flex bg-white dark:bg-gray-800 border border-black/10 dark:border-white/10 rounded-xl mx-8 mr-6 my-4 shadow-sm hover:shadow-md transition-all">
        <div className="absolute top-4 -left-6 z-10 flex flex-col font-grotesk">
          {provider.deliveryAvailable ? (
            <div className=" p-2 py-1 text-sm text-black bg-white dark:bg-gray-700 dark:text-white border border-black/10 shadow rounded-md my-2">
              <span>
                {provider.deliveryAvailable ? t("deliveryavailable") : ""}
              </span>
            </div>
          ) : null}
          {provider.pickupAvailable ? (
            <div className=" p-2 py-1 text-sm text-black bg-white dark:bg-gray-700 dark:text-white border border-black/10 shadow rounded-md my-2">
              <span>
                {provider.pickupAvailable ? t("pickupavailable") : ""}
              </span>
            </div>
          ) : null}
          {/* {(provider.deliveryAvailable && !provider.pickupAvailable) || (!provider.deliveryAvailable && provider.pickupAvailable) ?
                    <div className="font-rambla p-2 text-sm text-white bg-providercardoverlay rounded-sm" >
                        <span>{provider.deliveryAvailable}</span>
                    </div >
                    : null} */}
        </div>

        {provider?.payment_methods?.credit == true ? (
          <div className="absolute left-2 bottom-2 h-6 flex z-10 w-full">
            <Image
              className="object-center mr-2"
              height={24}
              width={45}
              src="/cards/visa.svg"
              alt="Visa"
            />
            <Image
              className="object-center"
              height={24}
              width={45}
              src="/cards/mastercard.svg"
              alt="Mastercard"
            />
            {provider?.payment_methods?.monni_card == true && (
              <Image
                className="object-center"
                height={24}
                width={45}
                src="/cards/monni.svg"
                alt="Monni"
              />
            )}
          </div>
        ) : null}

        {provider?.payment_methods?.credit == false &&
          provider?.payment_methods?.monni_card == true && (
            <Image
              className="object-center"
              height={24}
              width={45}
              src="/cards/monni.svg"
              alt="Monni"
            />
          )}

        <div
          className="bg-gray-900 dark:bg-gray-800 flex items-center justify-center w-28 md:w-44 rounded-l-xl"
          style={backgroundStyle}
        >
          {provider.picURL ? (
            <div className="relative w-16 md:w-20 h-16 md:h-20 mx-2 rounded-full">
              <Image
                layout="fill"
                className="rounded-full"
                src={provider?.picURL}
                alt={`${provider?.name} avatar`}
              />
            </div>
          ) : null}
        </div>
        <div className="flex-1 flex flex-col rounded-r-xl">
          {provider.store_acceptOrders && isOpen ? (
            <div
              suppressHydrationWarning
              className="h-20 bg-providercardopenbg text-providercardopentext dark:text-providercardopenbg dark:bg-providercardopentext flex items-center justify-center rounded-tr-xl"
            >
              <i className="text-3xl las la-clipboard-list mr-4"></i>
              <span className="font-rambla text-md font-normal uppercase">
                {t("orderhere")}
              </span>
            </div>
          ) : (
            <div
              suppressHydrationWarning
              className="h-20 bg-providercardclosedbg text-providercardclosedtext flex items-center justify-center rounded-tr-xl"
            >
              <i className="text-3xl las la-clipboard-list mr-4"></i>
              <span className="font-rambla text-md font-normal uppercase">
                {t("viewmenu")}
              </span>
            </div>
          )}

          <div className="p-4 text-providercardtext dark:text-white">
            <h2 className="italic font-rambla text-3xl group-hover:underline">
              {provider.name}
            </h2>

            {provider?.rating && provider.rating.count ? (
              <div className="flex my-2 items-center text-sm">
                {createRangeFromNumber(fullStars).map((_, i) => (
                  <Icon
                    size={0.8}
                    path={mdiStar}
                    key={i}
                    className="text-amber-500 dark:text-amber-500 MuiSvgIcon-fontSizeInherit"
                  />
                ))}
                {provider &&
                provider.rating?.avg % 1 <= 0.5 &&
                provider.rating.avg % 1 > 0 ? (
                  <Icon
                    size={0.8}
                    path={mdiStarHalfFull}
                    className="text-amber-500 dark:text-amber-500 MuiSvgIcon-fontSizeInherit"
                  />
                ) : null}

                {createRangeFromNumber(
                  5 -
                    fullStars -
                    (provider &&
                    provider.rating?.avg % 1 <= 0.5 &&
                    provider.rating.avg % 1 > 0
                      ? 1
                      : 0)
                ).map((_, i) => (
                  <Icon
                    size={0.8}
                    path={mdiStarOutline}
                    key={i}
                    className="text-providercardtext dark:text-white MuiSvgIcon-fontSizeInherit"
                  />
                ))}

                {provider.rating?.count ? (
                  <span className="text-providercardtext dark:text-white text-center text-xs ml-2">
                    ({provider.rating.count})
                  </span>
                ) : null}
              </div>
            ) : null}
            {locale && provider.town[locale] ? (
              <p className="mb-4 font-rambla">{provider.town[locale]}</p>
            ) : null}

            <div className="text-sm text-gray-500">
              {provider.min_order_price ? (
                <div className="flex items-center">
                  <i className="text-xl las la-money-bill-wave mr-2"></i>
                  <span>
                    {provider.min_order_price} {t("minorderprice")}
                  </span>
                </div>
              ) : null}

              {provider.delivery_time && provider.delivery_time !== "00:00" ? (
                <div className="flex items-center">
                  <i className="text-xl las la-clock mr-2"></i>
                  <span>
                    {provider.delivery_time}{" "}
                    {provider.deliveryAvailable
                      ? t("deliverytime")
                      : t("waitingtime")}
                  </span>
                </div>
              ) : null}

              {provider.delivery_costs && provider.deliveryAvailable ? (
                <div className="flex items-center">
                  <i className="text-xl las la-shipping-fast mr-2"></i>
                  <span>
                    {provider.delivery_costs} {t("deliverycosts")}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <span className="flex-1"></span>
          {user ? (
            <div className="flex justify-end p-2">
              <button
                className={`${iconButton} z-10`}
                onClick={(event) =>
                  setPinnedProvider(
                    event,
                    user.uid,
                    provider.uid,
                    clientData?.client_favouriteProviderIds &&
                      clientData.client_favouriteProviderIds.includes(
                        provider.uid
                      )
                  )
                }
                aria-label="pin"
              >
                {clientData?.client_favouriteProviderIds &&
                clientData.client_favouriteProviderIds.includes(
                  provider.uid
                ) ? (
                  <Icon
                    size={1}
                    path={mdiPin}
                    className="text-grey dark:text-gray-300"
                  />
                ) : (
                  <Icon
                    size={1}
                    path={mdiPinOutline}
                    className="text-grey dark:text-gray-300"
                  />
                )}
              </button>
            </div>
          ) : null}
        </div>
      </a>
    </Link>
  );

  function setPinnedProvider(
    event: any,
    userId: string,
    uid: string,
    isPinned: boolean
  ) {
    event.preventDefault();
    const clientDoc = doc(collection(firestore, "Clients"), userId);
    const partial = {
      client_favouriteProviderIds: isPinned
        ? arrayRemove(uid)
        : arrayUnion(uid),
    };

    return setDoc(clientDoc, partial, { merge: true }).catch((error) => {
      console.error(error);
    });
  }

  function createRangeFromNumber(length: number) {
    const arr = [];
    for (let i = 0; i < length; i++) {
      arr.push(0);
    }
    return arr;
  }
}

import { Transition } from "@headlessui/react";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import React from "react";

import useInterval from "../lib/interval";
import classNames from "classnames";
import { ArrowRightIcon } from "@heroicons/react/outline";

interface Slide {
  headline?: string;
  description?: string;
  src: string;
  color: string;
  rectangleType: any;
}

export default function AnimatedCarousel() {
  const [activeStep, setActiveStep] = useState<number>(0);

  useInterval(() => {
    nextPage();
  }, 10000);

  function getBackground(slide: Slide): any {
    return {
      background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) center center / cover, url('${slide.src}') no-repeat`,
      backgroundSize: "cover",
    };
  }

  function previousPage() {
    const previous = activeStep - 1;
    const value = previous < 0 ? slides.length - 1 : previous;
    setActiveStep(value);
  }

  function nextPage() {
    const next = activeStep + 1;
    const value = next === slides.length ? 0 : next;
    setActiveStep(value);
  }

  const slides: Slide[] = [
    {
      /* Order */
      headline: "carousel.title1",
      description: "carousel.description1",
      rectangleType: bottomLeftTopRight,
      color: "#cd5c5c",
      src: "/carousel/home-order.jpg",
    },
    {
      /* Calls */
      headline: "carousel.title2",
      description: "carousel.description2",
      rectangleType: topLeftBottomRight,
      color: "#cd5c5c",
      src: "/carousel/home-calls.jpg",
    },
    {
      /* Menu */
      headline: "carousel.title3",
      description: "carousel.description3",
      rectangleType: bottomLeftTopRight,
      color: "#cd5c5c",
      src: "/carousel/home-menu.jpg",
    },
    {
      /* Custom Pizza */
      headline: "carousel.title5",
      description: "carousel.description5",
      rectangleType: bottomLeftTopRight,
      color: "#cd5c5c",
      src: "/carousel/home-custompizza.jpg",
    },
  ];

  const { t } = useTranslation("home");

  const rectangle = (
    <div className="absolute inset-0 h-[400px] min-h-[400px] overflow-hidden">
      {bottomLeftTopRight(activeStep % 2 == 0)}
      {topLeftBottomRight(activeStep % 2 == 1)}
    </div>
  );

  return (
    <div className="shadow-lg relative h-[400px] min-h-[400px] bg-gray-300">
      {slides && slides.length ? (
        slides.map((slide, i) => (
          <div
            key={slide.headline}
            className={`flex flex-col items-center justify-center absolute inset-0`}
          >
            <Transition
              key={i}
              show={i === activeStep}
              enter="transition-all ease-linear duration-500"
              enterFrom="opacity-80"
              enterTo="opacity-100"
              leave="transition-all ease-linear duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-80"
            >
              <div
                className={`absolute inset-0`}
                style={getBackground(slide)}
              ></div>

              <div
                className={`absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 w-4/5 max-w-2xl`}
              >
                <h2 className="text-5xl text-white font-semibold text-center border-b-2 mb-3 pb-3 border-white">
                  {t(`${slide.headline}`)}
                </h2>
                <p className="text-2xl text-white text-center">
                  {t(`${slide.description}`)}
                </p>
              </div>
            </Transition>
          </div>
        ))
      ) : (
        <span>noslide</span>
      )}

      {rectangle}

      <button
        className="absolute transition-all left-0 text-white text-opacity-80 hover:text-opacity-100 top-1/2 transform -translate-y-1/2"
        onClick={previousPage}
      >
        <i className="text-3xl md:text-6xl las la-angle-left"></i>
      </button>
      <button
        className="absolute transition-all right-0 text-white text-opacity-80 hover:text-opacity-100 top-1/2 transform -translate-y-1/2"
        onClick={nextPage}
      >
        <i className="text-3xl md:text-6xl las la-angle-right"></i>
      </button>

      <div className="hidden md:block m-2 absolute left-0 bottom-0">
        <a
          className="text-white hover:text-white"
          href="https://facebook.com/bringmos/"
          target="_blank"
          rel="noreferrer"
        >
          <i className="text-3xl lab la-facebook"></i>
        </a>
        <a
          className="text-white hover:text-white2"
          href="https://www.instagram.com/bringmos/"
          target="_blank"
          rel="noreferrer"
        >
          <i className="text-3xl lab la-instagram"></i>
        </a>
      </div>

      <div className="z-10 cursor-pointer absolute left-4 md:left-auto right-4 bottom-4 md:bottom-2 flex flex-row justify-center">
        <a href="https://bringmos.io" target="_blank" rel="noreferrer">
          <div
            className={classNames(
              "text-white border bg-white/20 hover:bg-white/10 transition-all backdrop-blur border-divider-dark group relative mt-4 w-fit rounded-full p-[1px]"
            )}
          >
            <div
              className={classNames(
                "flex h-full w-full flex-row items-center rounded-full p-2 text-sm dark:bg-[rgb(11,13,30)]"
              )}
            >
              <div className="shadow mr-2 w-fit rounded-full bg-blue-500 px-2 py-[2px] text-xs">
                NEW
              </div>
              <span
                className="font-semibold 
          transition-all"
              >
                {t("registerforfree")}
              </span>
              <ArrowRightIcon className="transition-all transform group-hover:translate-x-2 ml-2 mr-3 h-4" />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

const topLeftBottomRight = (show: boolean): JSX.Element => (
  <>
    <Transition
      show={show}
      enter="transition-all transform transform-gpu duration-500 delay-300"
      enterFrom="-translate-y-60 -translate-x-60 opacity-0"
      enterTo="-translate-y-0 translate-x-0 opacity-100"
      leave="transition-all transform transform-gpu duration-300"
      leaveFrom="-translate-y-0 translate-x-0 opacity-100"
      leaveTo="-translate-y-60 -translate-x-60 opacity-0"
    >
      <svg
        className="absolute top-0 left-0 w-40 h-40 md:w-60 md:h-60 fill-current text-gray-800"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 800 800"
      >
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Ebene_1-2" data-name="Ebene 1">
            <polygon points="800 0 0 0.13 0.01 800 800 0" />
          </g>
        </g>
      </svg>
      <svg
        className="absolute top-0 left-0 w-20 h-20 md:w-44 md:h-44 fill-current text-carouselyellowstripe"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
      >
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Ebene_1-2" data-name="Ebene 1">
            <polygon points="500 0 0 0 0 500 500 0" />
          </g>
        </g>
      </svg>
    </Transition>
    <Transition
      className="absolute bottom-0 right-0"
      show={show}
      enter="transition-all transform transform-gpu duration-500 delay-300"
      enterFrom="translate-y-60 translate-x-60 opacity-0"
      enterTo="translate-y-0 translate-x-0 opacity-100"
      leave="transition-all transform transform-gpu duration-300"
      leaveFrom="translate-y-0 translate-x-0 opacity-100"
      leaveTo="translate-y-60 translate-x-60 opacity-0"
    >
      <div className="relative">
        <svg
          version="1.1"
          id="Ebene_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          className="absolute bottom-0 right-0 w-40 h-40 md:w-60 md:h-60 fill-current text-gray-800"
          viewBox="0 0 1420.3 1420.2"
          xmlSpace="preserve"
        >
          <g id="Ebene_2_1_">
            <g id="Ebene_1-2">
              <polygon points="1420.2,0 0,1420 1420.3,1420.2" />
            </g>
          </g>
        </svg>

        <svg
          className="absolute bottom-0 right-0 w-32 h-32 md:w-56 md:h-56 fill-current text-carouselyellowstripe"
          version="1.1"
          id="Ebene_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1115.5 1113.2"
          xmlSpace="preserve"
        >
          <g id="Ebene_2_1_">
            <g id="Ebene_1-2">
              <polygon points="0,1113.2 127.9,1113.1 1114.4,126.7 1115.5,0" />
            </g>
          </g>
        </svg>
      </div>
    </Transition>
  </>
);

const bottomLeftTopRight = (show: boolean): JSX.Element => (
  <>
    <Transition
      className="absolute bottom-0 left-0"
      show={show}
      enter="transition-all transition-opacity transform transform-gpu duration-500 delay-300"
      enterFrom="translate-y-60 -translate-x-60 opacity-0"
      enterTo="translate-y-0 translate-x-0 opacity-100"
      leave="transition-all transition-opacity transform transform-gpu duration-300"
      leaveFrom="translate-y-0 translate-x-0 opacity-100"
      leaveTo="translate-y-60 -translate-x-60 opacity-0"
    >
      <div className="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1420.31 1420.16"
          className="absolute bottom-0 left-0 w-40 h-40 md:w-60 md:h-60 fill-current text-gray-800"
        >
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <polygon points="0 1420.16 1420.31 1420.04 0.12 0 0 1420.16" />
            </g>
          </g>
        </svg>
        <svg
          className="absolute bottom-0 left-0 w-40 h-40 md:w-60 md:h-60 fill-current text-carouselbluestripe"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1115.5 1113.18"
        >
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <polygon points="0 0 1.08 126.66 987.62 1113.1 1115.5 1113.18 0 0" />
            </g>
          </g>
        </svg>
      </div>
    </Transition>

    <Transition
      show={show}
      enter="transition-all transition-opacity transform transform-gpu duration-500 delay-300"
      enterFrom="-translate-y-60 translate-x-60 opacity-0"
      enterTo="translate-y-0 translate-x-0 opacity-100"
      leave="transition-all transition-opacity transform transform-gpu duration-300"
      leaveFrom="translate-y-0 translate-x-0 opacity-100"
      leaveTo="-translate-y-60 translate-x-60 opacity-0"
    >
      <div className="relative">
        <svg
          className="absolute top-0 right-0 w-40 h-40 md:w-60 md:h-60 fill-current text-gray-800"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1618.86 1618.69"
        >
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <polygon points="1618.87 0 0 0.14 1618.72 1618.69 1618.87 0" />
            </g>
          </g>
        </svg>
        <svg
          className="absolute top-0 right-0 w-20 h-20 md:w-40 md:h-40 fill-current text-carouselbluestripe"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 921.05 919.14"
        >
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <polygon points="921.05 0 0 0 921.05 919.13 921.05 0" />
            </g>
          </g>
        </svg>
      </div>
    </Transition>
  </>
);

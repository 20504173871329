export default function MultiplaformDevices() {
    return (
        <div className="container max-w-5xl m-auto mt-20 grid grid-cols-1 md:grid-cols-2 px-4">
            <div className="md:bg-menu-device bg-top md:bg-cover pt-8 md:h-96 2xl:h-150 md:bg-no-repeat object-cover flex flex-col items-center">
                <a className="transform -translate-y-20" href="https://play.google.com/store/apps/details?id=com.bringmos.bringmosclient" target="_blank" rel="noreferrer">
                    <img className="block h-14 object-contain " alt="Playstore logo" src="images/playstore.png"
                        loading="lazy" /></a>
            </div>
            <div className="md:bg-custom-device md:bg-top md:bg-cover pt-8 md:h-96 2xl:h-150 md:bg-no-repeat object-cover flex flex-col items-center">
                <a className="transform -translate-y-20" target="_blank" rel="noreferrer"
                    href="https://apps.apple.com/us/app/bringmos/id1497420702?l=de&ls=1">
                    <img className="block h-14 object-contain" src="images/appstore.svg" loading="lazy" alt="Appstore logo" /></a>
            </div>
        </div>
    );
}